import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { mapEventToProfile } from "@snort/system";
import Logo from "Element/Logo";
import useEventPublisher from "Feed/EventPublisher";
import useLogin from "Hooks/useLogin";
import { useUserProfile } from "Hooks/useUserProfile";
import { UserCache } from "Cache";
import AvatarEditor from "Element/AvatarEditor";
import { DISCOVER } from ".";
import { System } from "index";
import messages from "./messages";
export default function ProfileSetup() {
    const login = useLogin();
    const myProfile = useUserProfile(login.publicKey);
    const [username, setUsername] = useState("");
    const [picture, setPicture] = useState("");
    const { formatMessage } = useIntl();
    const publisher = useEventPublisher();
    const navigate = useNavigate();
    useEffect(() => {
        if (myProfile) {
            setUsername(myProfile.name ?? "");
            setPicture(myProfile.picture ?? "");
        }
    }, [myProfile]);
    const onNext = async () => {
        if ((username.length > 0 || picture.length > 0) && publisher) {
            const ev = await publisher.metadata({
                ...myProfile,
                name: username,
                picture,
            });
            System.BroadcastEvent(ev);
            const profile = mapEventToProfile(ev);
            if (profile) {
                UserCache.set(profile);
            }
        }
        navigate(DISCOVER);
    };
    return (_jsxs("div", { className: "main-content new-user", dir: "auto", children: [_jsx(Logo, {}), _jsx("div", { className: "progress-bar", children: _jsx("div", { className: "progress progress-second" }) }), _jsx("h1", { children: _jsx(FormattedMessage, { id: 'Zr5TMx', defaultMessage: 'Setup profile' }) }), _jsx("h2", { children: _jsx(FormattedMessage, { id: 'O9GTIc', defaultMessage: 'Profile picture' }) }), _jsx(AvatarEditor, { picture: picture, onPictureChange: p => setPicture(p) }), _jsx("h2", { children: _jsx(FormattedMessage, { id: 'JCIgkj', defaultMessage: 'Username' }) }), _jsx("input", { className: "username", placeholder: formatMessage(messages.UsernamePlaceholder), type: "text", value: username, onChange: ev => setUsername(ev.target.value) }), _jsx("div", { className: "help-text", children: _jsx(FormattedMessage, { id: 'QawghE', defaultMessage: 'You can change your username at any point.' }) }), _jsxs("div", { className: "next-actions", children: [_jsx("button", { type: "button", className: "transparent", onClick: () => navigate(DISCOVER), children: _jsx(FormattedMessage, { ...messages.Skip }) }), _jsx("button", { type: "button", onClick: onNext, children: _jsx(FormattedMessage, { ...messages.Next }) })] })] }));
}
