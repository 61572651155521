import { db } from "Db";
import { FeedCache } from "@snort/shared";
class Payments extends FeedCache {
    constructor() {
        super("PaymentsCache", db.payments);
    }
    key(of) {
        return of.url;
    }
    takeSnapshot() {
        return [...this.cache.values()];
    }
}
export const PaymentsCache = new Payments();
