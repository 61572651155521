import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./ProfilePreview.css";
import ProfileImage from "Element/ProfileImage";
import FollowButton from "Element/FollowButton";
import { useUserProfile } from "Hooks/useUserProfile";
import { useInView } from "react-intersection-observer";
export default function ProfilePreview(props) {
    const pubkey = props.pubkey;
    const { ref, inView } = useInView({ triggerOnce: true });
    const user = useUserProfile(inView ? pubkey : undefined);
    const options = {
        about: true,
        ...props.options,
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: `profile-preview${props.className ? ` ${props.className}` : ""}`, ref: ref, children: inView && (_jsxs(_Fragment, { children: [_jsx(ProfileImage, { pubkey: pubkey, subHeader: options.about ? _jsx("div", { className: "about", children: user?.about }) : undefined }), props.actions ?? (_jsx("div", { className: "follow-button-container", children: _jsx(FollowButton, { pubkey: pubkey }) }))] })) }) }));
}
