import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import Timeline from "Element/Timeline";
import { TabElement } from "Element/Tabs";
import { useEffect, useState } from "react";
import { debounce } from "SnortUtils";
import { System, router } from "index";
import { SearchRelays } from "Const";
import TrendingUsers from "Element/TrendingUsers";
import TrendingNotes from "Element/TrendingPosts";
const NOTES = 0;
const PROFILES = 1;
const SearchPage = () => {
    const params = useParams();
    const { formatMessage } = useIntl();
    const [search, setSearch] = useState(params.keyword);
    const [keyword, setKeyword] = useState(params.keyword);
    const [sortPopular, setSortPopular] = useState(true);
    // tabs
    const SearchTab = {
        Posts: { text: formatMessage({ id: "7+Domh", defaultMessage: "Notes" }), value: NOTES },
        Profiles: { text: formatMessage({ id: "Tpy00S", defaultMessage: "People" }), value: PROFILES },
    };
    const [tab, setTab] = useState(SearchTab.Posts);
    useEffect(() => {
        if (keyword) {
            // "navigate" changing only url
            router.navigate(`/search/${encodeURIComponent(keyword)}`);
        }
        else {
            router.navigate(`/search`);
        }
    }, [keyword]);
    useEffect(() => {
        return debounce(500, () => setKeyword(search));
    }, [search]);
    useEffect(() => {
        const addedRelays = [];
        for (const [k, v] of SearchRelays) {
            if (!System.Sockets.some(v => v.address === k)) {
                System.ConnectToRelay(k, v);
                addedRelays.push(k);
            }
        }
        return () => {
            for (const r of addedRelays) {
                System.DisconnectRelay(r);
            }
        };
    }, []);
    function tabContent() {
        if (!keyword) {
            switch (tab.value) {
                case PROFILES:
                    return _jsx(TrendingUsers, {});
                case NOTES:
                    return _jsx(TrendingNotes, {});
            }
            return null;
        }
        const pf = tab.value == PROFILES;
        return (_jsxs(_Fragment, { children: [sortOptions(), _jsx(Timeline, { subject: {
                        type: pf ? "profile_keyword" : "post_keyword",
                        items: [keyword + (sortPopular ? " sort:popular" : "")],
                        discriminator: keyword,
                    }, postsOnly: false, noSort: pf && sortPopular, method: "LIMIT_UNTIL", loadMore: false }, keyword + (pf ? "_p" : ""))] }));
    }
    function sortOptions() {
        if (tab.value != PROFILES)
            return null;
        return (_jsxs("div", { className: "flex mb10 f-end", children: [_jsx(FormattedMessage, { id: 'RwFaYs', defaultMessage: 'Sort' }), "\u00A0", _jsxs("select", { onChange: e => setSortPopular(e.target.value == "true"), value: sortPopular ? "true" : "false", children: [_jsx("option", { value: "true", children: _jsx(FormattedMessage, { id: 'mTJFgF', defaultMessage: 'Popular' }) }), _jsx("option", { value: "false", children: _jsx(FormattedMessage, { id: 'RjpoYG', defaultMessage: 'Recent' }) })] })] }));
    }
    function renderTab(v) {
        return _jsx(TabElement, { t: v, tab: tab, setTab: setTab }, v.value);
    }
    return (_jsxs("div", { className: "main-content", children: [_jsx("h2", { children: _jsx(FormattedMessage, { id: 'xmcVZ0', defaultMessage: 'Search' }) }), _jsx("div", { className: "flex mb10", children: _jsx("input", { type: "text", className: "f-grow mr10", placeholder: formatMessage({ id: "0BUTMv", defaultMessage: "Search..." }), value: search, onChange: e => setSearch(e.target.value), autoFocus: true }) }), _jsx("div", { className: "tabs", children: [SearchTab.Posts, SearchTab.Profiles].map(renderTab) }), tabContent()] }));
};
export default SearchPage;
