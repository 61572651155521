import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./DmWindow.css";
import { useEffect, useMemo, useRef } from "react";
import ProfileImage from "Element/ProfileImage";
import DM from "Element/DM";
import { dmsForLogin, dmsInChat, isToSelf } from "Pages/MessagesPage";
import NoteToSelf from "Element/NoteToSelf";
import { useDmCache } from "Hooks/useDmsCache";
import useLogin from "Hooks/useLogin";
import WriteDm from "Element/WriteDm";
import { unwrap } from "SnortUtils";
export default function DmWindow({ id }) {
    const pubKey = useLogin().publicKey;
    const dmListRef = useRef(null);
    function resize(chatList) {
        if (!chatList.parentElement)
            return;
        const scrollWrap = unwrap(chatList.parentElement);
        const h = scrollWrap.scrollHeight;
        const s = scrollWrap.clientHeight + scrollWrap.scrollTop;
        const pos = Math.abs(h - s);
        const atBottom = pos === 0;
        //console.debug("Resize", h, s, pos, atBottom);
        if (atBottom) {
            scrollWrap.scrollTo(0, scrollWrap.scrollHeight);
        }
    }
    useEffect(() => {
        if (dmListRef.current) {
            const scrollWrap = dmListRef.current;
            const chatList = unwrap(scrollWrap.parentElement);
            chatList.onscroll = () => {
                resize(dmListRef.current);
            };
            new ResizeObserver(() => resize(dmListRef.current)).observe(scrollWrap);
            return () => {
                chatList.onscroll = null;
                new ResizeObserver(() => resize(dmListRef.current)).unobserve(scrollWrap);
            };
        }
    }, [dmListRef]);
    return (_jsxs("div", { className: "dm-window", children: [_jsx("div", { children: (id === pubKey && _jsx(NoteToSelf, { className: "f-grow mb-10", pubkey: id })) || (_jsx(ProfileImage, { pubkey: id, className: "f-grow mb10" })) }), _jsx("div", { children: _jsx("div", { className: "flex f-col", ref: dmListRef, children: _jsx(DmChatSelected, { chatPubKey: id }) }) }), _jsx("div", { children: _jsx(WriteDm, { chatPubKey: id }) })] }));
}
function DmChatSelected({ chatPubKey }) {
    const dms = useDmCache();
    const { publicKey: myPubKey } = useLogin();
    const sortedDms = useMemo(() => {
        if (myPubKey) {
            const myDms = dmsForLogin(dms, myPubKey);
            // filter dms in this chat, or dms to self
            const thisDms = myPubKey === chatPubKey ? myDms.filter(d => isToSelf(d, myPubKey)) : myDms;
            return [...dmsInChat(thisDms, chatPubKey)].sort((a, b) => a.created_at - b.created_at);
        }
        return [];
    }, [dms, myPubKey, chatPubKey]);
    return (_jsx(_Fragment, { children: sortedDms.map(a => (_jsx(DM, { data: a }, a.id))) }));
}
