import { FeedCache } from "@snort/shared";
import { db } from "Db";
class DMCache extends FeedCache {
    constructor() {
        super("DMCache", db.dms);
    }
    key(of) {
        return of.id;
    }
    async preload() {
        await super.preload();
        // load all dms to memory
        await this.buffer([...this.onTable]);
    }
    newest() {
        let ret = 0;
        this.cache.forEach(v => (ret = v.created_at > ret ? v.created_at : ret));
        return ret;
    }
    allDms() {
        return [...this.cache.values()];
    }
    takeSnapshot() {
        return this.allDms();
    }
}
export const DmCache = new DMCache();
