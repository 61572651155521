import { useMemo } from "react";
import { EventKind, RequestBuilder, ReplaceableNoteStore } from "@snort/system";
import useRequestBuilder from "Hooks/useRequestBuilder";
export default function useRelaysFeed(pubkey) {
    const sub = useMemo(() => {
        if (!pubkey)
            return null;
        const b = new RequestBuilder(`relays:${pubkey.slice(0, 12)}`);
        b.withFilter().authors([pubkey]).kinds([EventKind.ContactList]);
        return b;
    }, [pubkey]);
    const relays = useRequestBuilder(ReplaceableNoteStore, sub);
    if (!relays.data?.content) {
        return [];
    }
    try {
        return Object.entries(JSON.parse(relays.data.content)).map(([url, settings]) => ({
            url,
            settings,
        }));
    }
    catch (error) {
        console.error(error);
        return [];
    }
}
