import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { FileExtensionRegex } from "Const";
import Reveal from "Element/Reveal";
import useLogin from "Hooks/useLogin";
import { MediaElement } from "Element/MediaElement";
export default function RevealMedia(props) {
    const login = useLogin();
    const { preferences: pref, follows, publicKey } = login;
    const hideNonFollows = pref.autoLoadMedia === "follows-only" && !follows.item.includes(props.creator);
    const isMine = props.creator === publicKey;
    const hideMedia = pref.autoLoadMedia === "none" || (!isMine && hideNonFollows);
    const hostname = new URL(props.link).hostname;
    const url = new URL(props.link);
    const extension = FileExtensionRegex.test(url.pathname.toLowerCase()) && RegExp.$1;
    const type = (() => {
        switch (extension) {
            case "gif":
            case "jpg":
            case "jpeg":
            case "jfif":
            case "png":
            case "bmp":
            case "webp":
                return "image";
            case "wav":
            case "mp3":
            case "ogg":
                return "audio";
            case "mp4":
            case "mov":
            case "mkv":
            case "avi":
            case "m4v":
            case "webm":
                return "video";
            default:
                return "unknown";
        }
    })();
    if (hideMedia) {
        return (_jsx(Reveal, { message: _jsx(FormattedMessage, { id: 'lsNFM1', defaultMessage: 'Click to load content from {link}', values: { link: hostname } }), children: _jsx(MediaElement, { mime: `${type}/${extension}`, url: url.toString() }) }));
    }
    else {
        return _jsx(MediaElement, { mime: `${type}/${extension}`, url: url.toString() });
    }
}
