import { useEffect, useSyncExternalStore } from "react";
import { ProfileLoader } from "index";
import { UserCache } from "Cache";
export function useUserProfile(pubKey) {
    const user = useSyncExternalStore(h => UserCache.hook(h, pubKey), () => UserCache.getFromCache(pubKey));
    useEffect(() => {
        if (pubKey) {
            ProfileLoader.TrackMetadata(pubKey);
            return () => ProfileLoader.UntrackMetadata(pubKey);
        }
    }, [pubKey]);
    return user;
}
