import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Text.css";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { NostrPrefix, validateNostrLink } from "@snort/system";
import { MentionRegex, InvoiceRegex, HashtagRegex, CashuRegex } from "Const";
import { eventLink, hexToBech32, splitByUrl } from "SnortUtils";
import Invoice from "Element/Invoice";
import Hashtag from "Element/Hashtag";
import Mention from "Element/Mention";
import HyperText from "Element/HyperText";
import CashuNuts from "Element/CashuNuts";
import { ProxyImg } from "Element/ProxyImg";
export default function Text({ content, tags, creator, disableMedia, depth }) {
    const location = useLocation();
    function extractLinks(fragments) {
        return fragments
            .map(f => {
            if (typeof f === "string") {
                return splitByUrl(f).map(a => {
                    const validateLink = () => {
                        const normalizedStr = a.toLowerCase();
                        if (normalizedStr.startsWith("web+nostr:") || normalizedStr.startsWith("nostr:")) {
                            return validateNostrLink(normalizedStr);
                        }
                        return (normalizedStr.startsWith("http:") ||
                            normalizedStr.startsWith("https:") ||
                            normalizedStr.startsWith("magnet:"));
                    };
                    if (validateLink()) {
                        if (disableMedia ?? false) {
                            return (_jsx("a", { href: a, onClick: e => e.stopPropagation(), target: "_blank", rel: "noreferrer", className: "ext", children: a }));
                        }
                        return _jsx(HyperText, { link: a, creator: creator, depth: depth });
                    }
                    return a;
                });
            }
            return f;
        })
            .flat();
    }
    function extractCashuTokens(fragments) {
        return fragments
            .map(f => {
            if (typeof f === "string" && f.includes("cashuA")) {
                return f.split(CashuRegex).map(a => {
                    return _jsx(CashuNuts, { token: a });
                });
            }
            return f;
        })
            .flat();
    }
    function extractMentions(frag) {
        return frag.body
            .map(f => {
            if (typeof f === "string") {
                return f.split(MentionRegex).map(match => {
                    const matchTag = match.match(/#\[(\d+)\]/);
                    if (matchTag && matchTag.length === 2) {
                        const idx = parseInt(matchTag[1]);
                        const ref = frag.tags?.[idx];
                        if (ref) {
                            switch (ref[0]) {
                                case "p": {
                                    return _jsx(Mention, { pubkey: ref[1] ?? "", relays: ref[2] });
                                }
                                case "e": {
                                    const eText = hexToBech32(NostrPrefix.Event, ref[1]).substring(0, 12);
                                    return (ref[1] && (_jsxs(Link, { to: eventLink(ref[1], ref[2]), onClick: e => e.stopPropagation(), state: { from: location.pathname }, children: ["#", eText] })));
                                }
                                case "t": {
                                    return _jsx(Hashtag, { tag: ref[1] ?? "" });
                                }
                            }
                        }
                        return _jsxs("b", { style: { color: "var(--error)" }, children: [matchTag[0], "?"] });
                    }
                    else {
                        return match;
                    }
                });
            }
            return f;
        })
            .flat();
    }
    function extractInvoices(fragments) {
        return fragments
            .map(f => {
            if (typeof f === "string") {
                return f.split(InvoiceRegex).map(i => {
                    if (i.toLowerCase().startsWith("lnbc")) {
                        return _jsx(Invoice, { invoice: i });
                    }
                    else {
                        return i;
                    }
                });
            }
            return f;
        })
            .flat();
    }
    function extractHashtags(fragments) {
        return fragments
            .map(f => {
            if (typeof f === "string") {
                return f.split(HashtagRegex).map(i => {
                    if (i.toLowerCase().startsWith("#")) {
                        return _jsx(Hashtag, { tag: i.substring(1) });
                    }
                    else {
                        return i;
                    }
                });
            }
            return f;
        })
            .flat();
    }
    function extractCustomEmoji(fragments) {
        return fragments
            .map(f => {
            if (typeof f === "string") {
                return f.split(/:(\w+):/g).map(i => {
                    const t = tags.find(a => a[0] === "emoji" && a[1] === i);
                    if (t) {
                        return _jsx(ProxyImg, { src: t[2], size: 15, className: "custom-emoji" });
                    }
                    else {
                        return i;
                    }
                });
            }
            return f;
        })
            .flat();
    }
    function transformText(frag) {
        let fragments = extractMentions(frag);
        fragments = extractLinks(fragments);
        fragments = extractInvoices(fragments);
        fragments = extractHashtags(fragments);
        fragments = extractCashuTokens(fragments);
        fragments = extractCustomEmoji(fragments);
        return fragments;
    }
    const element = useMemo(() => {
        return _jsx("div", { className: "text", children: transformText({ body: [content], tags }) });
    }, [content]);
    return _jsx("div", { dir: "auto", children: element });
}
