import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./Thread.css";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { EventKind, NostrPrefix, EventExt, parseNostrLink, } from "@snort/system";
import { eventLink, unwrap, getReactions, getAllReactions, findTag } from "SnortUtils";
import BackButton from "Element/BackButton";
import Note from "Element/Note";
import NoteGhost from "Element/NoteGhost";
import Collapsed from "Element/Collapsed";
import useThreadFeed from "Feed/ThreadFeed";
import messages from "./messages";
const Divider = ({ variant = "regular" }) => {
    const className = variant === "small" ? "divider divider-small" : "divider";
    return (_jsx("div", { className: "divider-container", children: _jsx("div", { className: className }) }));
};
const Subthread = ({ active, notes, related, chains, onNavigate }) => {
    const renderSubthread = (a, idx) => {
        const isLastSubthread = idx === notes.length - 1;
        const replies = getReplies(a.id, chains);
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: `subthread-container ${replies.length > 0 ? "subthread-multi" : ""}`, children: [_jsx(Divider, {}), _jsx(Note, { highlight: active === a.id, className: `thread-note ${isLastSubthread && replies.length === 0 ? "is-last-note" : ""}`, data: a, related: related, onClick: onNavigate }, a.id), _jsx("div", { className: "line-container" })] }), replies.length > 0 && (_jsx(TierTwo, { active: active, isLastSubthread: isLastSubthread, notes: replies, related: related, chains: chains, onNavigate: onNavigate }))] }));
    };
    return _jsx("div", { className: "subthread", children: notes.map(renderSubthread) });
};
const ThreadNote = ({ active, note, isLast, isLastSubthread, related, chains, onNavigate }) => {
    const { formatMessage } = useIntl();
    const replies = getReplies(note.id, chains);
    const activeInReplies = replies.map(r => r.id).includes(active);
    const [collapsed, setCollapsed] = useState(!activeInReplies);
    const hasMultipleNotes = replies.length > 1;
    const isLastVisibleNote = isLastSubthread && isLast && !hasMultipleNotes;
    const className = `subthread-container ${isLast && collapsed ? "subthread-last" : "subthread-multi subthread-mid"}`;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: className, children: [_jsx(Divider, { variant: "small" }), _jsx(Note, { highlight: active === note.id, className: `thread-note ${isLastVisibleNote ? "is-last-note" : ""}`, data: note, related: related, onClick: onNavigate }, note.id), _jsx("div", { className: "line-container" })] }), replies.length > 0 && (_jsx(Collapsed, { text: formatMessage(messages.ShowReplies), collapsed: collapsed, setCollapsed: setCollapsed, children: _jsx(TierThree, { active: active, isLastSubthread: isLastSubthread, notes: replies, related: related, chains: chains, onNavigate: onNavigate }) }))] }));
};
const TierTwo = ({ active, isLastSubthread, notes, related, chains, onNavigate }) => {
    const [first, ...rest] = notes;
    return (_jsxs(_Fragment, { children: [_jsx(ThreadNote, { active: active, onNavigate: onNavigate, note: first, chains: chains, related: related, isLastSubthread: isLastSubthread, isLast: rest.length === 0 }), rest.map((r, idx) => {
                const lastReply = idx === rest.length - 1;
                return (_jsx(ThreadNote, { active: active, onNavigate: onNavigate, note: r, chains: chains, related: related, isLastSubthread: isLastSubthread, isLast: lastReply }));
            })] }));
};
const TierThree = ({ active, isLastSubthread, notes, related, chains, onNavigate }) => {
    const [first, ...rest] = notes;
    const replies = getReplies(first.id, chains);
    const hasMultipleNotes = rest.length > 0 || replies.length > 0;
    const isLast = replies.length === 0 && rest.length === 0;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: `subthread-container ${hasMultipleNotes ? "subthread-multi" : ""} ${isLast ? "subthread-last" : "subthread-mid"}`, children: [_jsx(Divider, { variant: "small" }), _jsx(Note, { highlight: active === first.id, className: `thread-note ${isLastSubthread && isLast ? "is-last-note" : ""}`, data: first, related: related }, first.id), _jsx("div", { className: "line-container" })] }), replies.length > 0 && (_jsx(TierThree, { active: active, isLastSubthread: isLastSubthread, notes: replies, related: related, chains: chains, onNavigate: onNavigate })), rest.map((r, idx) => {
                const lastReply = idx === rest.length - 1;
                const lastNote = isLastSubthread && lastReply;
                return (_jsxs("div", { className: `subthread-container ${lastReply ? "" : "subthread-multi"} ${lastReply ? "subthread-last" : "subthread-mid"}`, children: [_jsx(Divider, { variant: "small" }), _jsx(Note, { className: `thread-note ${lastNote ? "is-last-note" : ""}`, highlight: active === r.id, data: r, related: related, onClick: onNavigate }, r.id), _jsx("div", { className: "line-container" })] }, r.id));
            })] }));
};
export default function Thread() {
    const params = useParams();
    const location = useLocation();
    const link = parseNostrLink(params.id ?? "", NostrPrefix.Note);
    const thread = useThreadFeed(link);
    const [currentId, setCurrentId] = useState(link.id);
    const navigate = useNavigate();
    const isSingleNote = thread.data?.filter(a => a.kind === EventKind.TextNote).length === 1;
    const { formatMessage } = useIntl();
    function navigateThread(e) {
        setCurrentId(e.id);
        //const link = encodeTLV(e.id, NostrPrefix.Event, e.relays);
    }
    const chains = useMemo(() => {
        const chains = new Map();
        if (thread.data) {
            thread.data
                ?.filter(a => a.kind === EventKind.TextNote)
                .sort((a, b) => b.created_at - a.created_at)
                .forEach(v => {
                const t = EventExt.extractThread(v);
                let replyTo = t?.replyTo?.value ?? t?.root?.value;
                if (t?.root?.key === "a" && t?.root?.value) {
                    const parsed = t.root.value.split(":");
                    replyTo = thread.data?.find(a => a.kind === Number(parsed[0]) && a.pubkey === parsed[1] && findTag(a, "d") === parsed[2])?.id;
                }
                if (replyTo) {
                    if (!chains.has(replyTo)) {
                        chains.set(replyTo, [v]);
                    }
                    else {
                        unwrap(chains.get(replyTo)).push(v);
                    }
                }
            });
        }
        return chains;
    }, [thread.data]);
    // Root is the parent of the current note or the current note if its a root note or the root of the thread
    const root = useMemo(() => {
        const currentNote = thread.data?.find(ne => ne.id === currentId) ??
            (location.state && "sig" in location.state ? location.state : undefined);
        if (currentNote) {
            const currentThread = EventExt.extractThread(currentNote);
            const isRoot = (ne) => ne === undefined;
            if (isRoot(currentThread)) {
                return currentNote;
            }
            const replyTo = currentThread?.replyTo ?? currentThread?.root;
            // sometimes the root event ID is missing, and we can only take the happy path if the root event ID exists
            if (replyTo) {
                if (replyTo.key === "a" && replyTo.value) {
                    const parsed = replyTo.value.split(":");
                    return thread.data?.find(a => a.kind === Number(parsed[0]) && a.pubkey === parsed[1] && findTag(a, "d") === parsed[2]);
                }
                if (replyTo.value) {
                    return thread.data?.find(a => a.id === replyTo.value);
                }
            }
            const possibleRoots = thread.data?.filter(a => {
                const thread = EventExt.extractThread(a);
                return isRoot(thread);
            });
            if (possibleRoots) {
                // worst case we need to check every possible root to see which one contains the current note as a child
                for (const ne of possibleRoots) {
                    const children = chains.get(ne.id) ?? [];
                    if (children.find(ne => ne.id === currentId)) {
                        return ne;
                    }
                }
            }
        }
    }, [thread.data, currentId, location]);
    const parent = useMemo(() => {
        if (root) {
            const currentThread = EventExt.extractThread(root);
            return (currentThread?.replyTo?.value ??
                currentThread?.root?.value ??
                (currentThread?.root?.key === "a" && currentThread.root?.value));
        }
    }, [root]);
    const brokenChains = Array.from(chains?.keys()).filter(a => !thread.data?.some(b => b.id === a));
    function renderRoot(note) {
        const className = `thread-root${isSingleNote ? " thread-root-single" : ""}`;
        if (note) {
            return (_jsx(Note, { className: className, data: note, related: getReactions(thread.data, note.id), options: { showReactionsLink: true }, onClick: navigateThread }, note.id));
        }
        else {
            return _jsxs(NoteGhost, { className: className, children: ["Loading thread root.. (", thread.data?.length, " notes loaded)"] });
        }
    }
    function renderChain(from) {
        if (!from || !chains) {
            return;
        }
        const replies = chains.get(from);
        if (replies && currentId) {
            return (_jsx(Subthread, { active: currentId, notes: replies, related: getAllReactions(thread.data, replies.map(a => a.id)), chains: chains, onNavigate: navigateThread }));
        }
    }
    function goBack() {
        if (parent) {
            setCurrentId(parent);
        }
        else {
            navigate(-1);
        }
    }
    const parentText = formatMessage({ id: "ADmfQT", defaultMessage: "Parent" });
    const backText = formatMessage({ id: "jfV8Wr", defaultMessage: "Back" });
    return (_jsxs("div", { className: "main-content mt10", children: [_jsx(BackButton, { onClick: goBack, text: parent ? parentText : backText }), _jsxs("div", { className: "thread-container", children: [root && renderRoot(root), root && renderChain(root.id), brokenChains.length > 0 && _jsx("h3", { children: "Other replies" }), brokenChains.map(a => {
                        return (_jsxs("div", { className: "mb10", children: [_jsxs(NoteGhost, { className: `thread-note thread-root ghost-root`, children: ["Missing event ", _jsx(Link, { to: eventLink(a), children: a.substring(0, 8) })] }, a), renderChain(a)] }));
                    })] })] }));
}
function getReplies(from, chains) {
    if (!from || !chains) {
        return [];
    }
    const replies = chains.get(from);
    return replies ? replies : [];
}
