import { FeedCache } from "@snort/shared";
import { db } from "Db";
import { LoginStore } from "Login";
import { sha256 } from "SnortUtils";
class EventInteractionCache extends FeedCache {
    constructor() {
        super("EventInteraction", db.eventInteraction);
    }
    key(of) {
        return sha256(of.event + of.by);
    }
    async preload() {
        await super.preload();
        const data = window.localStorage.getItem("zap-cache");
        if (data) {
            const toImport = [...new Set(JSON.parse(data))].map(a => {
                const ret = {
                    event: a,
                    by: LoginStore.takeSnapshot().publicKey,
                    zapped: true,
                    reacted: false,
                    reposted: false,
                };
                ret.id = this.key(ret);
                return ret;
            });
            await this.bulkSet(toImport);
            console.debug(`Imported dumb-zap-cache events: `, toImport.length);
            window.localStorage.removeItem("zap-cache");
        }
        await this.buffer([...this.onTable]);
    }
    takeSnapshot() {
        return [...this.cache.values()];
    }
}
export const InteractionCache = new EventInteractionCache();
