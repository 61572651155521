import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./ZapButton.css";
import { useState } from "react";
import { useUserProfile } from "Hooks/useUserProfile";
import SendSats from "Element/SendSats";
import Icon from "Icons/Icon";
const ZapButton = ({ pubkey, lnurl, children }) => {
    const profile = useUserProfile(pubkey);
    const [zap, setZap] = useState(false);
    const service = lnurl ?? (profile?.lud16 || profile?.lud06);
    if (!service)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "zap-button flex", onClick: () => setZap(true), children: [_jsx(Icon, { name: "zap", className: children ? "mr5" : "", size: 15 }), children] }), _jsx(SendSats, { target: profile?.display_name || profile?.name, lnurl: service, show: zap, onClose: () => setZap(false), author: pubkey })] }));
};
export default ZapButton;
