import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./ProfileImage.css";
import { useMemo } from "react";
import { NostrPrefix } from "@snort/system";
import { useUserProfile } from "Hooks/useUserProfile";
import { hexToBech32, profileLink } from "SnortUtils";
import Avatar from "Element/Avatar";
import Nip05 from "Element/Nip05";
import { Link } from "react-router-dom";
export default function ProfileImage({ pubkey, subHeader, showUsername = true, className, link, defaultNip, verifyNip, overrideUsername, }) {
    const user = useUserProfile(pubkey);
    const nip05 = defaultNip ? defaultNip : user?.nip05;
    const name = useMemo(() => {
        return overrideUsername ?? getDisplayName(user, pubkey);
    }, [user, pubkey, overrideUsername]);
    function handleClick(e) {
        if (link === "") {
            e.preventDefault();
        }
    }
    return (_jsxs(Link, { className: `pfp${className ? ` ${className}` : ""}`, to: link === undefined ? profileLink(pubkey) : link, onClick: handleClick, replace: true, children: [_jsx("div", { className: "avatar-wrapper", children: _jsx(Avatar, { user: user }) }), showUsername && (_jsxs("div", { className: "f-ellipsis", children: [_jsxs("div", { className: "username", children: [_jsx("div", { children: name.trim() }), nip05 && _jsx(Nip05, { nip05: nip05, pubkey: pubkey, verifyNip: verifyNip })] }), _jsx("div", { className: "subheader", children: subHeader })] }))] }));
}
export function getDisplayName(user, pubkey) {
    let name = hexToBech32(NostrPrefix.PublicKey, pubkey).substring(0, 12);
    if (typeof user?.display_name === "string" && user.display_name.length > 0) {
        name = user.display_name;
    }
    else if (typeof user?.name === "string" && user.name.length > 0) {
        name = user.name;
    }
    return name;
}
