import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./Nip05.css";
import Icon from "Icons/Icon";
import { useUserProfile } from "Hooks/useUserProfile";
export function useIsVerified(pubkey, bypassCheck) {
    const profile = useUserProfile(pubkey);
    return { isVerified: bypassCheck || profile?.isNostrAddressValid };
}
const Nip05 = ({ nip05, pubkey, verifyNip = true }) => {
    const [name, domain] = nip05 ? nip05.split("@") : [];
    const isDefaultUser = name === "_";
    const { isVerified } = useIsVerified(pubkey, !verifyNip);
    return (_jsxs("div", { className: `flex nip05${!isVerified ? " failed" : ""}`, children: [!isDefaultUser && isVerified && _jsx("span", { className: "nick", children: `${name}@` }), isVerified && (_jsxs(_Fragment, { children: [_jsx("span", { className: "domain", "data-domain": domain?.toLowerCase(), children: domain }), _jsx(Icon, { name: "badge", className: "badge", size: 16 })] }))] }));
};
export default Nip05;
