import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Note.css";
import React, { useMemo, useState, useLayoutEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useIntl, FormattedMessage } from "react-intl";
import { EventKind, NostrPrefix, Lists, EventExt } from "@snort/system";
import { System } from "index";
import useEventPublisher from "Feed/EventPublisher";
import Icon from "Icons/Icon";
import { parseZap } from "Element/Zap";
import ProfileImage from "Element/ProfileImage";
import Text from "Element/Text";
import { eventLink, getReactions, dedupeByPubkey, tagFilterOfTextRepost, hexToBech32, normalizeReaction, Reaction, profileLink, } from "SnortUtils";
import NoteFooter from "Element/NoteFooter";
import NoteTime from "Element/NoteTime";
import Reveal from "Element/Reveal";
import useModeration from "Hooks/useModeration";
import { UserCache } from "Cache";
import Poll from "Element/Poll";
import useLogin from "Hooks/useLogin";
import { setBookmarked, setPinned } from "Login";
import { NostrFileElement } from "Element/NostrFileHeader";
import ZapstrEmbed from "Element/ZapstrEmbed";
import PubkeyList from "Element/PubkeyList";
import messages from "./messages";
const HiddenNote = ({ children }) => {
    const [show, setShow] = useState(false);
    return show ? (_jsx(_Fragment, { children: children })) : (_jsx("div", { className: "card note hidden-note", children: _jsxs("div", { className: "header", children: [_jsx("p", { children: _jsx(FormattedMessage, { ...messages.MutedAuthor }) }), _jsx("button", { onClick: () => setShow(true), children: _jsx(FormattedMessage, { ...messages.Show }) })] }) }));
};
export default function Note(props) {
    const { data: ev, related, highlight, options: opt, ignoreModeration = false, className } = props;
    if (ev.kind === EventKind.FileHeader) {
        return _jsx(NostrFileElement, { ev: ev });
    }
    if (ev.kind === EventKind.ZapstrTrack) {
        return _jsx(ZapstrEmbed, { ev: ev });
    }
    if (ev.kind === EventKind.PubkeyLists) {
        return _jsx(PubkeyList, { ev: ev, className: className });
    }
    const baseClassName = `note card${className ? ` ${className}` : ""}`;
    const navigate = useNavigate();
    const [showReactions, setShowReactions] = useState(false);
    const deletions = useMemo(() => getReactions(related, ev.id, EventKind.Deletion), [related]);
    const { isMuted } = useModeration();
    const isOpMuted = isMuted(ev?.pubkey);
    const { ref, inView, entry } = useInView({ triggerOnce: true });
    const [extendable, setExtendable] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const login = useLogin();
    const { pinned, bookmarked } = login;
    const publisher = useEventPublisher();
    const [translated, setTranslated] = useState();
    const { formatMessage } = useIntl();
    const reactions = useMemo(() => getReactions(related, ev.id, EventKind.Reaction), [related, ev]);
    const groupReactions = useMemo(() => {
        const result = reactions?.reduce((acc, reaction) => {
            const kind = normalizeReaction(reaction.content);
            const rs = acc[kind] || [];
            return { ...acc, [kind]: [...rs, reaction] };
        }, {
            [Reaction.Positive]: [],
            [Reaction.Negative]: [],
        });
        return {
            [Reaction.Positive]: dedupeByPubkey(result[Reaction.Positive]),
            [Reaction.Negative]: dedupeByPubkey(result[Reaction.Negative]),
        };
    }, [reactions]);
    const positive = groupReactions[Reaction.Positive];
    const negative = groupReactions[Reaction.Negative];
    const reposts = useMemo(() => dedupeByPubkey([
        ...getReactions(related, ev.id, EventKind.TextNote).filter(e => e.tags.some(tagFilterOfTextRepost(e, ev.id))),
        ...getReactions(related, ev.id, EventKind.Repost),
    ]), [related, ev]);
    const zaps = useMemo(() => {
        const sortedZaps = getReactions(related, ev.id, EventKind.ZapReceipt)
            .map(a => parseZap(a, ev))
            .filter(z => z.valid);
        sortedZaps.sort((a, b) => b.amount - a.amount);
        return sortedZaps;
    }, [related]);
    const totalReactions = positive.length + negative.length + reposts.length + zaps.length;
    const options = {
        showHeader: true,
        showTime: true,
        showFooter: true,
        canUnpin: false,
        canUnbookmark: false,
        ...opt,
    };
    async function unpin(id) {
        if (options.canUnpin && publisher) {
            if (window.confirm(formatMessage(messages.ConfirmUnpin))) {
                const es = pinned.item.filter(e => e !== id);
                const ev = await publisher.noteList(es, Lists.Pinned);
                System.BroadcastEvent(ev);
                setPinned(login, es, ev.created_at * 1000);
            }
        }
    }
    async function unbookmark(id) {
        if (options.canUnbookmark && publisher) {
            if (window.confirm(formatMessage(messages.ConfirmUnbookmark))) {
                const es = bookmarked.item.filter(e => e !== id);
                const ev = await publisher.noteList(es, Lists.Bookmarked);
                System.BroadcastEvent(ev);
                setBookmarked(login, es, ev.created_at * 1000);
            }
        }
    }
    const transformBody = () => {
        const body = ev?.content ?? "";
        if (deletions?.length > 0) {
            return (_jsx("b", { className: "error", children: _jsx(FormattedMessage, { ...messages.Deleted }) }));
        }
        const contentWarning = ev.tags.find(a => a[0] === "content-warning");
        if (contentWarning) {
            return (_jsx(Reveal, { message: _jsxs(_Fragment, { children: [_jsx(FormattedMessage, { id: 'x/q8d5', defaultMessage: 'This note has been marked as sensitive, click here to reveal' }), contentWarning[1] && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx(FormattedMessage, { id: 'KLo3SP', defaultMessage: 'Reason: {reason}', values: {
                                        reason: contentWarning[1],
                                    } })] }))] }), children: _jsx(Text, { content: body, tags: ev.tags, creator: ev.pubkey }) }));
        }
        return _jsx(Text, { content: body, tags: ev.tags, creator: ev.pubkey, depth: props.depth });
    };
    useLayoutEffect(() => {
        if (entry && inView && extendable === false) {
            const h = entry?.target?.offsetHeight ?? 0;
            if (h > 650) {
                setExtendable(true);
            }
        }
    }, [inView, entry, extendable]);
    function goToEvent(e, eTarget, isTargetAllowed = e.target === e.currentTarget) {
        if (!isTargetAllowed || opt?.canClick === false) {
            return;
        }
        e.stopPropagation();
        if (props.onClick) {
            props.onClick(eTarget);
            return;
        }
        const link = eventLink(eTarget.id, eTarget.relays);
        // detect cmd key and open in new tab
        if (e.metaKey) {
            window.open(link, "_blank");
        }
        else {
            navigate(link, {
                state: ev,
            });
        }
    }
    function replyTag() {
        const thread = EventExt.extractThread(ev);
        if (thread === undefined) {
            return undefined;
        }
        const maxMentions = 2;
        const replyId = thread?.replyTo?.value ?? thread?.root?.value;
        const replyRelayHints = thread?.replyTo?.relay ?? thread.root?.relay;
        const mentions = [];
        for (const pk of thread?.pubKeys ?? []) {
            const u = UserCache.getFromCache(pk);
            const npub = hexToBech32(NostrPrefix.PublicKey, pk);
            const shortNpub = npub.substring(0, 12);
            mentions.push({
                pk,
                name: u?.name ?? shortNpub,
                link: _jsx(Link, { to: profileLink(pk), children: u?.name ? `@${u.name}` : shortNpub }),
            });
        }
        mentions.sort(a => (a.name.startsWith(NostrPrefix.PublicKey) ? 1 : -1));
        const othersLength = mentions.length - maxMentions;
        const renderMention = (m, idx) => {
            return (_jsxs(React.Fragment, { children: [idx > 0 && ", ", m.link] }, m.pk));
        };
        const pubMentions = mentions.length > maxMentions ? mentions?.slice(0, maxMentions).map(renderMention) : mentions?.map(renderMention);
        const others = mentions.length > maxMentions ? formatMessage(messages.Others, { n: othersLength }) : "";
        return (_jsxs("div", { className: "reply", children: ["re:\u00A0", (mentions?.length ?? 0) > 0 ? (_jsxs(_Fragment, { children: [pubMentions, " ", others] })) : (replyId && (_jsx(Link, { to: eventLink(replyId, replyRelayHints), children: hexToBech32(NostrPrefix.Event, replyId)?.substring(0, 12) })))] }));
    }
    const canRenderAsTextNote = [EventKind.TextNote, EventKind.Polls];
    if (!canRenderAsTextNote.includes(ev.kind)) {
        return (_jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(FormattedMessage, { ...messages.UnknownEventKind, values: { kind: ev.kind } }) }), _jsx("pre", { children: JSON.stringify(ev, undefined, "  ") })] }));
    }
    function translation() {
        if (translated && translated.confidence > 0.5) {
            return (_jsxs(_Fragment, { children: [_jsx("p", { className: "highlight", children: _jsx(FormattedMessage, { ...messages.TranslatedFrom, values: { lang: translated.fromLanguage } }) }), translated.text] }));
        }
        else if (translated) {
            return (_jsx("p", { className: "highlight", children: _jsx(FormattedMessage, { ...messages.TranslationFailed }) }));
        }
    }
    function pollOptions() {
        if (ev.kind !== EventKind.Polls)
            return;
        return _jsx(Poll, { ev: ev, zaps: zaps });
    }
    function content() {
        if (!inView)
            return undefined;
        return (_jsxs(_Fragment, { children: [options.showHeader && (_jsxs("div", { className: "header flex", children: [_jsx(ProfileImage, { pubkey: ev.pubkey, subHeader: replyTag() ?? undefined, link: opt?.canClick === undefined ? undefined : "" }), (options.showTime || options.showBookmarked) && (_jsxs("div", { className: "info", children: [options.showBookmarked && (_jsxs("div", { className: `saved ${options.canUnbookmark ? "pointer" : ""}`, onClick: () => unbookmark(ev.id), children: [_jsx(Icon, { name: "bookmark" }), " ", _jsx(FormattedMessage, { ...messages.Bookmarked })] })), !options.showBookmarked && _jsx(NoteTime, { from: ev.created_at * 1000 })] })), options.showPinned && (_jsxs("div", { className: `pinned ${options.canUnpin ? "pointer" : ""}`, onClick: () => unpin(ev.id), children: [_jsx(Icon, { name: "pin" }), " ", _jsx(FormattedMessage, { ...messages.Pinned })] }))] })), _jsxs("div", { className: "body", onClick: e => goToEvent(e, ev, true), children: [transformBody(), translation(), pollOptions(), options.showReactionsLink && (_jsx("div", { className: "reactions-link", onClick: () => setShowReactions(true), children: _jsx(FormattedMessage, { ...messages.ReactionsLink, values: { n: totalReactions } }) }))] }), extendable && !showMore && (_jsx("span", { className: "expand-note mt10 flex f-center", onClick: () => setShowMore(true), children: _jsx(FormattedMessage, { ...messages.ShowMore }) })), options.showFooter && (_jsx(NoteFooter, { ev: ev, positive: positive, negative: negative, reposts: reposts, zaps: zaps, onTranslated: t => setTranslated(t), showReactions: showReactions, setShowReactions: setShowReactions }))] }));
    }
    const note = (_jsx("div", { className: `${baseClassName}${highlight ? " active " : " "}${extendable && !showMore ? " note-expand" : ""}`, onClick: e => goToEvent(e, ev), ref: ref, children: content() }));
    return !ignoreModeration && isOpMuted ? _jsx(HiddenNote, { children: note }) : note;
}
