import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { NostrPrefix } from "@snort/system";
import UnreadCount from "Element/UnreadCount";
import ProfileImage, { getDisplayName } from "Element/ProfileImage";
import { dedupe, hexToBech32, unwrap } from "SnortUtils";
import NoteToSelf from "Element/NoteToSelf";
import useModeration from "Hooks/useModeration";
import { useDmCache } from "Hooks/useDmsCache";
import useLogin from "Hooks/useLogin";
import usePageWidth from "Hooks/usePageWidth";
import NoteTime from "Element/NoteTime";
import DmWindow from "Element/DmWindow";
import Avatar from "Element/Avatar";
import { useUserProfile } from "Hooks/useUserProfile";
import Icon from "Icons/Icon";
import Text from "Element/Text";
import "./MessagesPage.css";
import messages from "./messages";
const TwoCol = 768;
const ThreeCol = 1500;
export default function MessagesPage() {
    const login = useLogin();
    const { isMuted } = useModeration();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const dms = useDmCache();
    const [chat, setChat] = useState();
    const pageWidth = usePageWidth();
    const chats = useMemo(() => {
        if (login.publicKey) {
            return extractChats(dms.filter(a => !isMuted(a.pubkey)), login.publicKey);
        }
        return [];
    }, [dms, login.publicKey, isMuted]);
    const unreadCount = useMemo(() => chats.reduce((p, c) => p + c.unreadMessages, 0), [chats]);
    function openChat(e, pubkey) {
        e.stopPropagation();
        e.preventDefault();
        if (pageWidth < TwoCol) {
            navigate(`/messages/${hexToBech32(NostrPrefix.PublicKey, pubkey)}`);
        }
        else {
            setChat(pubkey);
        }
    }
    function noteToSelf(chat) {
        return (_jsx("div", { className: "flex mb10", onClick: e => openChat(e, chat.pubkey), children: _jsx(NoteToSelf, { clickable: true, className: "f-grow", link: "", pubkey: chat.pubkey }) }, chat.pubkey));
    }
    function person(chat) {
        if (!login.publicKey)
            return null;
        if (chat.pubkey === login.publicKey)
            return noteToSelf(chat);
        return (_jsxs("div", { className: "flex mb10", onClick: e => openChat(e, chat.pubkey), children: [_jsx(ProfileImage, { pubkey: chat.pubkey, className: "f-grow", link: "" }), _jsxs("div", { className: "nowrap", children: [_jsx("small", { children: _jsx(NoteTime, { from: newestMessage(dms, login.publicKey, chat.pubkey) * 1000, fallback: formatMessage({ id: "bxv59V", defaultMessage: "Just now" }) }) }), chat.unreadMessages > 0 && _jsx(UnreadCount, { unread: chat.unreadMessages })] })] }, chat.pubkey));
    }
    function markAllRead() {
        for (const c of chats) {
            setLastReadDm(c.pubkey);
        }
    }
    return (_jsxs("div", { className: "dm-page", children: [_jsxs("div", { children: [_jsxs("div", { className: "flex", children: [_jsx("h3", { className: "f-grow", children: _jsx(FormattedMessage, { ...messages.Messages }) }), _jsx("button", { disabled: unreadCount <= 0, type: "button", onClick: () => markAllRead(), children: _jsx(FormattedMessage, { ...messages.MarkAllRead }) })] }), chats
                        .sort((a, b) => {
                        return a.pubkey === login.publicKey
                            ? -1
                            : b.pubkey === login.publicKey
                                ? 1
                                : b.newestMessage - a.newestMessage;
                    })
                        .map(person)] }), pageWidth >= TwoCol && chat && _jsx(DmWindow, { id: chat }), pageWidth >= ThreeCol && chat && (_jsx("div", { children: _jsx(ProfileDmActions, { pubkey: chat }) }))] }));
}
function ProfileDmActions({ pubkey }) {
    const profile = useUserProfile(pubkey);
    const { block, unblock, isBlocked } = useModeration();
    const blocked = isBlocked(pubkey);
    return (_jsxs(_Fragment, { children: [_jsx(Avatar, { user: profile, size: 210 }), _jsx("h2", { children: getDisplayName(profile, pubkey) }), _jsx("p", { children: _jsx(Text, { content: profile?.about ?? "", tags: [], creator: pubkey, disableMedia: true, depth: 0 }) }), _jsxs("div", { className: "settings-row", onClick: () => (blocked ? unblock(pubkey) : block(pubkey)), children: [_jsx(Icon, { name: "block" }), blocked ? _jsx(FormattedMessage, { id: 'nDejmx', defaultMessage: 'Unblock' }) : _jsx(FormattedMessage, { id: 'Up5U7K', defaultMessage: 'Block' })] })] }));
}
export function lastReadDm(pk) {
    const k = `dm:seen:${pk}`;
    return parseInt(window.localStorage.getItem(k) ?? "0");
}
export function setLastReadDm(pk) {
    const now = Math.floor(new Date().getTime() / 1000);
    const current = lastReadDm(pk);
    if (current >= now) {
        return;
    }
    const k = `dm:seen:${pk}`;
    window.localStorage.setItem(k, now.toString());
}
export function dmTo(e) {
    const firstP = e.tags.find(b => b[0] === "p");
    return unwrap(firstP?.[1]);
}
export function isToSelf(e, pk) {
    return e.pubkey === pk && dmTo(e) === pk;
}
export function dmsInChat(dms, pk) {
    return dms.filter(a => a.pubkey === pk || dmTo(a) === pk);
}
export function totalUnread(dms, myPubKey) {
    return extractChats(dms, myPubKey).reduce((acc, v) => (acc += v.unreadMessages), 0);
}
function unreadDms(dms, myPubKey, pk) {
    if (pk === myPubKey)
        return 0;
    const lastRead = lastReadDm(pk);
    return dmsInChat(dms, pk).filter(a => a.created_at >= lastRead && a.pubkey !== myPubKey).length;
}
function newestMessage(dms, myPubKey, pk) {
    if (pk === myPubKey) {
        return dmsInChat(dms.filter(d => isToSelf(d, myPubKey)), pk).reduce((acc, v) => (acc = v.created_at > acc ? v.created_at : acc), 0);
    }
    return dmsInChat(dms, pk).reduce((acc, v) => (acc = v.created_at > acc ? v.created_at : acc), 0);
}
export function dmsForLogin(dms, myPubKey) {
    return dms.filter(a => a.pubkey === myPubKey || (a.pubkey !== myPubKey && dmTo(a) === myPubKey));
}
export function extractChats(dms, myPubKey) {
    const myDms = dmsForLogin(dms, myPubKey);
    const keys = myDms.map(a => [a.pubkey, dmTo(a)]).flat();
    const filteredKeys = dedupe(keys);
    return filteredKeys.map(a => {
        return {
            pubkey: a,
            unreadMessages: unreadDms(myDms, myPubKey, a),
            newestMessage: newestMessage(myDms, myPubKey, a),
        };
    });
}
