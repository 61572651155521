import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./SubDebug.css";
import { useState } from "react";
import useRelayState from "Feed/RelayState";
import Tabs from "Element/Tabs";
import { unwrap } from "SnortUtils";
import useSystemState from "Hooks/useSystemState";
import { useCopy } from "useCopy";
import { System } from "index";
function RelayInfo({ id }) {
    const state = useRelayState(id);
    return _jsx("div", { children: state?.connected ? _jsx(_Fragment, { children: id }) : _jsx("s", { children: id }) }, id);
}
function Queries() {
    const qs = useSystemState();
    const { copy } = useCopy();
    function countElements(filters) {
        let total = 0;
        for (const f of filters) {
            for (const v of Object.values(f)) {
                if (Array.isArray(v)) {
                    total += v.length;
                }
            }
        }
        return total;
    }
    function queryInfo(q) {
        return (_jsxs("div", { children: [q.id, _jsx("br", {}), _jsxs("span", { onClick: () => copy(JSON.stringify(q.filters)), className: "pointer", children: ["\u00A0 Filters: ", q.filters.length, " (", countElements(q.filters), " elements)"] }), _jsx("br", {}), _jsxs("span", { onClick: () => copy(JSON.stringify(q.subFilters)), className: "pointer", children: ["\u00A0 SubQueries: ", q.subFilters.length, " (", countElements(q.subFilters), " elements)"] })] }, q.id));
    }
    return (_jsxs(_Fragment, { children: [_jsx("b", { children: "Queries" }), qs?.queries.map(v => queryInfo(v))] }));
}
const SubDebug = () => {
    const [onTab, setTab] = useState(0);
    function connections() {
        return (_jsxs(_Fragment, { children: [_jsx("b", { children: "Connections:" }), System.Sockets.map(k => (_jsx(RelayInfo, { id: k.address })))] }));
    }
    const tabs = [
        {
            text: "Connections",
            value: 0,
        },
        {
            text: "Queries",
            value: 1,
        },
    ];
    return (_jsxs("div", { className: "sub-debug", children: [_jsx(Tabs, { tabs: tabs, setTab: v => setTab(v.value), tab: unwrap(tabs.find(a => a.value === onTab)) }), (() => {
                switch (onTab) {
                    case 0:
                        return connections();
                    case 1:
                        return _jsx(Queries, {});
                    default:
                        return null;
                }
            })()] }));
};
export default SubDebug;
