import { useSyncExternalStore } from "react";
import { EmptySnapshot } from "@snort/system";
import { unwrap } from "SnortUtils";
import { System } from "index";
const useRequestBuilder = (type, rb) => {
    const subscribe = (onChanged) => {
        if (rb) {
            const q = System.Query(type, rb);
            const release = q.feed.hook(onChanged);
            q.uncancel();
            return () => {
                q.cancel();
                release();
            };
        }
        return () => {
            // noop
        };
    };
    const getState = () => {
        const q = System.GetQuery(rb?.id ?? "");
        if (q) {
            return unwrap(q).feed?.snapshot;
        }
        return EmptySnapshot;
    };
    return useSyncExternalStore(v => subscribe(v), () => getState());
};
export default useRequestBuilder;
