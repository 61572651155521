import { jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useUserProfile } from "Hooks/useUserProfile";
import { profileLink } from "SnortUtils";
import { getDisplayName } from "Element/ProfileImage";
export default function Mention({ pubkey, relays }) {
    const user = useUserProfile(pubkey);
    const name = useMemo(() => {
        return getDisplayName(user, pubkey);
    }, [user, pubkey]);
    return (_jsxs(Link, { to: profileLink(pubkey, relays), onClick: e => e.stopPropagation(), children: ["@", name] }));
}
