import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { mapEventToProfile } from "@snort/system";
import { unwrap } from "SnortUtils";
import { formatShort } from "Number";
import { ServiceProvider, } from "Nip05/ServiceProvider";
import AsyncButton from "Element/AsyncButton";
import SendSats from "Element/SendSats";
import Copy from "Element/Copy";
import { useUserProfile } from "Hooks/useUserProfile";
import useEventPublisher from "Feed/EventPublisher";
import { debounce } from "SnortUtils";
import useLogin from "Hooks/useLogin";
import SnortServiceProvider from "Nip05/SnortServiceProvider";
import { UserCache } from "Cache";
import messages from "./messages";
import { System } from "index";
export default function Nip5Service(props) {
    const navigate = useNavigate();
    const { helpText = true } = props;
    const { formatMessage } = useIntl();
    const pubkey = useLogin().publicKey;
    const user = useUserProfile(pubkey);
    const publisher = useEventPublisher();
    const svc = useMemo(() => new ServiceProvider(props.service), [props.service]);
    const [serviceConfig, setServiceConfig] = useState();
    const [error, setError] = useState();
    const [handle, setHandle] = useState("");
    const [domain, setDomain] = useState("");
    const [checking, setChecking] = useState(false);
    const [availabilityResponse, setAvailabilityResponse] = useState();
    const [registerResponse, setRegisterResponse] = useState();
    const [showInvoice, setShowInvoice] = useState(false);
    const [registerStatus, setRegisterStatus] = useState();
    const onHandleChange = (e) => {
        const h = e.target.value.toLowerCase();
        setHandle(h);
        if (props.onChange) {
            props.onChange(`${h}@${domain}`);
        }
    };
    const onDomainChange = (e) => {
        const d = e.target.value;
        setDomain(d);
        if (props.onChange) {
            props.onChange(`${handle}@${d}`);
        }
    };
    const domainConfig = useMemo(() => serviceConfig?.domains.find(a => a.name === domain), [domain, serviceConfig]);
    useEffect(() => {
        svc
            .GetConfig()
            .then(a => {
            if ("error" in a) {
                setError(a);
            }
            else {
                const svc = a;
                setServiceConfig(svc);
                const defaultDomain = svc.domains.find(a => a.default)?.name || svc.domains[0].name;
                setDomain(defaultDomain);
            }
        })
            .catch(console.error);
    }, [props, svc]);
    useEffect(() => {
        setError(undefined);
        setAvailabilityResponse(undefined);
        if (handle && domain) {
            if (handle.length < (domainConfig?.length[0] ?? 2)) {
                setAvailabilityResponse({ available: false, why: "TOO_SHORT" });
                return;
            }
            if (handle.length > (domainConfig?.length[1] ?? 20)) {
                setAvailabilityResponse({ available: false, why: "TOO_LONG" });
                return;
            }
            const rx = new RegExp(domainConfig?.regex[0] ?? "", domainConfig?.regex[1] ?? "");
            if (!rx.test(handle)) {
                setAvailabilityResponse({ available: false, why: "REGEX" });
                return;
            }
            return debounce(500, () => {
                svc
                    .CheckAvailable(handle, domain)
                    .then(a => {
                    if ("error" in a) {
                        setError(a);
                    }
                    else {
                        setAvailabilityResponse(a);
                    }
                })
                    .catch(console.error);
            });
        }
    }, [handle, domain, domainConfig, svc]);
    async function checkRegistration(rsp) {
        const status = await svc.CheckRegistration(rsp.token);
        if ("error" in status) {
            setError(status);
            setRegisterResponse(undefined);
            setShowInvoice(false);
        }
        else {
            const result = status;
            if (result.paid) {
                if (!result.available) {
                    setError({
                        error: "REGISTERED",
                    });
                }
                else {
                    setError(undefined);
                }
                setShowInvoice(false);
                setRegisterStatus(status);
                setRegisterResponse(undefined);
            }
        }
    }
    useEffect(() => {
        if (registerResponse && showInvoice && !checking) {
            const t = setInterval(() => {
                if (!checking) {
                    setChecking(true);
                    checkRegistration(registerResponse)
                        .then(() => setChecking(false))
                        .catch(e => {
                        console.error(e);
                        setChecking(false);
                    });
                }
            }, 2000);
            return () => clearInterval(t);
        }
    }, [registerResponse, showInvoice, svc, checking]);
    function mapError(e, t) {
        if (e === undefined) {
            return undefined;
        }
        const whyMap = new Map([
            ["TOO_SHORT", formatMessage(messages.TooShort)],
            ["TOO_LONG", formatMessage(messages.TooLong)],
            ["REGEX", formatMessage(messages.Regex)],
            ["REGISTERED", formatMessage(messages.Registered)],
            ["DISALLOWED_null", formatMessage(messages.Disallowed)],
            ["DISALLOWED_later", formatMessage(messages.DisalledLater)],
        ]);
        return whyMap.get(e === "DISALLOWED" ? `${e}_${t}` : e);
    }
    async function startBuy(handle, domain) {
        if (!pubkey) {
            return;
        }
        const rsp = await svc.RegisterHandle(handle, domain, pubkey);
        if ("error" in rsp) {
            setError(rsp);
        }
        else {
            setRegisterResponse(rsp);
            setShowInvoice(true);
        }
    }
    async function claimForSubscription(handle, domain, sub) {
        if (!pubkey || !publisher) {
            return;
        }
        const svcEx = new SnortServiceProvider(publisher, props.service);
        const rsp = await svcEx.registerForSubscription(handle, domain, sub);
        if ("error" in rsp) {
            setError(rsp);
        }
        else {
            if (props.onSuccess) {
                const nip05 = `${handle}@${domain}`;
                props.onSuccess(nip05);
            }
        }
    }
    async function updateProfile(handle, domain) {
        if (user && publisher) {
            const nip05 = `${handle}@${domain}`;
            const newProfile = {
                ...user,
                nip05,
            };
            const ev = await publisher.metadata(newProfile);
            System.BroadcastEvent(ev);
            if (props.onSuccess) {
                props.onSuccess(nip05);
            }
            const newMeta = mapEventToProfile(ev);
            if (newMeta) {
                UserCache.set(newMeta);
            }
            if (helpText) {
                navigate("/settings");
            }
        }
    }
    return (_jsxs(_Fragment, { children: [helpText && _jsx("h3", { children: props.name }), helpText && props.about, helpText && (_jsx("p", { children: _jsx(FormattedMessage, { ...messages.FindMore, values: {
                        service: props.name,
                        link: (_jsx("a", { href: props.link, target: "_blank", rel: "noreferrer", children: props.link })),
                    } }) })), error && _jsx("b", { className: "error", children: error.error }), !registerStatus && (_jsxs("div", { className: "flex mb10", children: [_jsx("input", { type: "text", className: "nip-handle", placeholder: formatMessage(messages.Handle), value: handle, onChange: onHandleChange }), "\u00A0@\u00A0", _jsx("select", { value: domain, onChange: onDomainChange, children: serviceConfig?.domains.map(a => (_jsx("option", { children: a.name }, a.name))) })] })), availabilityResponse?.available && !registerStatus && (_jsxs("div", { className: "flex", children: [!props.forSubscription && (_jsxs("div", { className: "mr10", children: [_jsx(FormattedMessage, { ...messages.Sats, values: { n: formatShort(unwrap(availabilityResponse.quote?.price)) } }), _jsx("br", {}), _jsx("small", { children: availabilityResponse.quote?.data.type })] })), _jsx(AsyncButton, { onClick: () => props.forSubscription
                            ? claimForSubscription(handle, domain, props.forSubscription)
                            : startBuy(handle, domain), children: props.forSubscription ? (_jsx(FormattedMessage, { id: 'FdhSU2', defaultMessage: 'Claim Now' })) : (_jsx(FormattedMessage, { ...messages.BuyNow })) })] })), availabilityResponse?.available === false && !registerStatus && (_jsx("div", { className: "flex", children: _jsxs("b", { className: "error", children: [_jsx(FormattedMessage, { ...messages.NotAvailable }), " ", mapError(availabilityResponse.why, availabilityResponse.reasonTag || null)] }) })), _jsx(SendSats, { invoice: registerResponse?.invoice, show: showInvoice, onClose: () => setShowInvoice(false), title: formatMessage(messages.Buying, { item: `${handle}@${domain}` }) }), registerStatus?.paid && (_jsxs("div", { className: "flex f-col", children: [_jsx("h4", { children: _jsx(FormattedMessage, { ...messages.OrderPaid }) }), _jsxs("p", { children: [_jsx(FormattedMessage, { ...messages.NewNip }), " ", _jsxs("code", { children: [handle, "@", domain] })] }), _jsx("h3", { children: _jsx(FormattedMessage, { ...messages.AccountSupport }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.SavePassword }) }), _jsx(Copy, { text: registerStatus.password }), _jsxs("p", { children: [_jsx(FormattedMessage, { ...messages.GoTo }), " ", _jsx("a", { href: props.supportLink, target: "_blank", rel: "noreferrer", children: _jsx(FormattedMessage, { ...messages.AccountPage }) })] }), _jsx("h4", { children: _jsx(FormattedMessage, { ...messages.ActivateNow }) }), _jsx(AsyncButton, { onClick: () => updateProfile(handle, domain), children: _jsx(FormattedMessage, { ...messages.AddToProfile }) })] }))] }));
}
