import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./ZapPool.css";
import { useMemo, useSyncExternalStore } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { SnortPubKey } from "Const";
import ProfilePreview from "Element/ProfilePreview";
import useLogin from "Hooks/useLogin";
import { UploaderServices } from "Upload";
import { bech32ToHex, getRelayName, unwrap } from "SnortUtils";
import { ZapPoolController, ZapPoolRecipientType } from "ZapPoolController";
import { useUserProfile } from "Hooks/useUserProfile";
import AsyncButton from "Element/AsyncButton";
import { useWallet } from "Wallet";
import { System } from "index";
const DataProviders = [
    {
        name: "nostr.band",
        owner: bech32ToHex("npub1sx9rnd03vs34lp39fvfv5krwlnxpl90f3dzuk8y3cuwutk2gdhdqjz6g8m"),
    },
    {
        name: "semisol.dev",
        owner: bech32ToHex("npub12262qa4uhw7u8gdwlgmntqtv7aye8vdcmvszkqwgs0zchel6mz7s6cgrkj"),
    },
    {
        name: "nostr.watch",
        owner: bech32ToHex("npub1uac67zc9er54ln0kl6e4qp2y6ta3enfcg7ywnayshvlw9r5w6ehsqq99rx"),
    },
    {
        name: "nostr.directory",
        owner: bech32ToHex("npub1teawtzxh6y02cnp9jphxm2q8u6xxfx85nguwg6ftuksgjctvavvqnsgq5u"),
    },
];
function ZapTarget({ target }) {
    const login = useLogin();
    const profile = useUserProfile(target.pubkey);
    const hasAddress = profile?.lud16 || profile?.lud06;
    const defaultZapMount = Math.ceil(login.preferences.defaultZapAmount * (target.split / 100));
    return (_jsx(ProfilePreview, { pubkey: target.pubkey, actions: hasAddress ? (_jsxs("div", { children: [_jsxs("div", { children: [_jsx(FormattedNumber, { value: target.split }), "% (", _jsx(FormattedMessage, { id: 'CsCUYo', defaultMessage: '{n} sats', values: { n: defaultZapMount } }), ")"] }), _jsx("input", { type: "range", min: 0, max: 100, value: target.split, onChange: e => ZapPoolController.set({
                        ...target,
                        split: e.target.valueAsNumber,
                    }) })] })) : (_jsx(FormattedMessage, { id: 'JPFYIM', defaultMessage: 'No lightning address' })) }));
}
export default function ZapPoolPage() {
    const login = useLogin();
    const zapPool = useSyncExternalStore(c => ZapPoolController.hook(c), () => ZapPoolController.snapshot());
    const { wallet } = useWallet();
    const relayConnections = useMemo(() => {
        return System.Sockets.map(a => {
            if (a.info?.pubkey && !a.ephemeral) {
                return {
                    address: a.address,
                    pubkey: a.info.pubkey,
                };
            }
        })
            .filter(a => a !== undefined)
            .map(unwrap);
    }, [login.relays]);
    const sumPending = zapPool.reduce((acc, v) => acc + v.sum, 0);
    return (_jsxs("div", { className: "zap-pool main-content", children: [_jsx("h1", { children: _jsx(FormattedMessage, { id: 'i/dBAR', defaultMessage: 'Zap Pool' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'x/Fx2P', defaultMessage: 'Fund the services that you use by splitting a portion of all your zaps into a pool of funds!' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'QWhotP', defaultMessage: 'Zap Pool only works if you use one of the supported wallet connections (WebLN, LNC, LNDHub or Nostr Wallet Connect)' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'Xopqkl', defaultMessage: 'Your default zap amount is {number} sats, example values are calculated from this.', values: {
                        number: (_jsx("b", { children: _jsx(FormattedNumber, { value: login.preferences.defaultZapAmount }) })),
                    } }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'eSzf2G', defaultMessage: 'A single zap of {nIn} sats will allocate {nOut} sats to the zap pool.', values: {
                        nIn: (_jsx("b", { children: _jsx(FormattedNumber, { value: login.preferences.defaultZapAmount }) })),
                        nOut: (_jsx("b", { children: _jsx(FormattedNumber, { value: ZapPoolController.calcAllocation(login.preferences.defaultZapAmount) }) })),
                    } }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'Qxv0B2', defaultMessage: 'You currently have {number} sats in your zap pool.', values: {
                        number: (_jsx("b", { children: _jsx(FormattedNumber, { value: sumPending }) })),
                    } }) }), _jsx("p", { children: wallet && (_jsx(AsyncButton, { onClick: () => ZapPoolController.payout(wallet), children: _jsx(FormattedMessage, { id: '+PzQ9Y', defaultMessage: 'Payout Now' }) })) }), _jsx("div", { className: "card", children: _jsx(ZapTarget, { target: zapPool.find(b => b.pubkey === bech32ToHex(SnortPubKey) && b.type === ZapPoolRecipientType.Generic) ?? {
                        type: ZapPoolRecipientType.Generic,
                        pubkey: bech32ToHex(SnortPubKey),
                        split: 0,
                        sum: 0,
                    } }) }), _jsx("h3", { children: _jsx(FormattedMessage, { id: 'RoOyAh', defaultMessage: 'Relays' }) }), relayConnections.map(a => (_jsxs("div", { className: "card", children: [_jsx("h4", { children: getRelayName(a.address) }), _jsx(ZapTarget, { target: zapPool.find(b => b.pubkey === a.pubkey && b.type === ZapPoolRecipientType.Relay) ?? {
                            type: ZapPoolRecipientType.Relay,
                            pubkey: a.pubkey,
                            split: 0,
                            sum: 0,
                        } })] }))), _jsx("h3", { children: _jsx(FormattedMessage, { id: 'XICsE8', defaultMessage: 'File hosts' }) }), UploaderServices.map(a => (_jsxs("div", { className: "card", children: [_jsx("h4", { children: a.name }), _jsx(ZapTarget, { target: zapPool.find(b => b.pubkey === a.owner && b.type === ZapPoolRecipientType.FileHost) ?? {
                            type: ZapPoolRecipientType.FileHost,
                            pubkey: a.owner,
                            split: 0,
                            sum: 0,
                        } })] }))), _jsx("h3", { children: _jsx(FormattedMessage, { id: 'ELbg9p', defaultMessage: 'Data Providers' }) }), DataProviders.map(a => (_jsxs("div", { className: "card", children: [_jsx("h4", { children: a.name }), _jsx(ZapTarget, { target: zapPool.find(b => b.pubkey === a.owner && b.type === ZapPoolRecipientType.DataProvider) ?? {
                            type: ZapPoolRecipientType.DataProvider,
                            pubkey: a.owner,
                            split: 0,
                            sum: 0,
                        } })] })))] }));
}
