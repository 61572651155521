import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Reactions.css";
import { useState, useMemo, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { formatShort } from "Number";
import Icon from "Icons/Icon";
import ProfileImage from "Element/ProfileImage";
import Tabs from "Element/Tabs";
import Modal from "Element/Modal";
import messages from "./messages";
const Reactions = ({ show, setShow, positive, negative, reposts, zaps }) => {
    const { formatMessage } = useIntl();
    const onClose = () => setShow(false);
    const likes = useMemo(() => {
        const sorted = [...positive];
        sorted.sort((a, b) => b.created_at - a.created_at);
        return sorted;
    }, [positive]);
    const dislikes = useMemo(() => {
        const sorted = [...negative];
        sorted.sort((a, b) => b.created_at - a.created_at);
        return sorted;
    }, [negative]);
    const total = positive.length + negative.length + zaps.length + reposts.length;
    const defaultTabs = [
        {
            text: formatMessage(messages.Likes, { n: likes.length }),
            value: 0,
        },
        {
            text: formatMessage(messages.Zaps, { n: zaps.length }),
            value: 1,
            disabled: zaps.length === 0,
        },
        {
            text: formatMessage(messages.Reposts, { n: reposts.length }),
            value: 2,
            disabled: reposts.length === 0,
        },
    ];
    const tabs = defaultTabs.concat(dislikes.length !== 0
        ? [
            {
                text: formatMessage(messages.Dislikes, { n: dislikes.length }),
                value: 3,
            },
        ]
        : []);
    const [tab, setTab] = useState(tabs[0]);
    useEffect(() => {
        if (!show) {
            setTab(tabs[0]);
        }
    }, [show]);
    return show ? (_jsxs(Modal, { className: "reactions-modal", onClose: onClose, children: [_jsx("div", { className: "close", onClick: onClose, children: _jsx(Icon, { name: "close" }) }), _jsx("div", { className: "reactions-header", children: _jsx("h2", { children: _jsx(FormattedMessage, { ...messages.ReactionsCount, values: { n: total } }) }) }), _jsx(Tabs, { tabs: tabs, tab: tab, setTab: setTab }), _jsxs("div", { className: "body", children: [tab.value === 0 &&
                        likes.map(ev => {
                            return (_jsxs("div", { className: "reactions-item", children: [_jsx("div", { className: "reaction-icon", children: ev.content === "+" ? _jsx(Icon, { name: "heart" }) : ev.content }), _jsx(ProfileImage, { pubkey: ev.pubkey })] }, ev.id));
                        }), tab.value === 1 &&
                        zaps.map(z => {
                            return (z.sender && (_jsxs("div", { className: "reactions-item", children: [_jsxs("div", { className: "zap-reaction-icon", children: [_jsx(Icon, { name: "zap", size: 20 }), _jsx("span", { className: "zap-amount", children: formatShort(z.amount) })] }), _jsx(ProfileImage, { pubkey: z.anonZap ? "" : z.sender, subHeader: _jsx("div", { title: z.content, children: z.content }), link: z.anonZap ? "" : undefined, overrideUsername: z.anonZap ? formatMessage({ id: "LXxsbk", defaultMessage: "Anonymous" }) : undefined })] }, z.id)));
                        }), tab.value === 2 &&
                        reposts.map(ev => {
                            return (_jsxs("div", { className: "reactions-item", children: [_jsx("div", { className: "reaction-icon", children: _jsx(Icon, { name: "repost", size: 16 }) }), _jsx(ProfileImage, { pubkey: ev.pubkey })] }, ev.id));
                        }), tab.value === 3 &&
                        dislikes.map(ev => {
                            return (_jsxs("div", { className: "reactions-item f-ellipsis", children: [_jsx("div", { className: "reaction-icon", children: _jsx(Icon, { name: "dislike" }) }), _jsx(ProfileImage, { pubkey: ev.pubkey })] }, ev.id));
                        })] }, tab.value)] })) : null;
};
export default Reactions;
