import * as secp from "@noble/curves/secp256k1";
import * as utils from "@noble/curves/abstract/utils";
import { sha256 as hash } from "@noble/hashes/sha256";
import { hmac } from "@noble/hashes/hmac";
import { bytesToHex } from "@noble/hashes/utils";
import { decode as invoiceDecode } from "light-bolt11-decoder";
import { bech32, base32hex } from "@scure/base";
import { encodeTLV, NostrPrefix, } from "@snort/system";
export const sha256 = (str) => {
    return utils.bytesToHex(hash(str));
};
export function getPublicKey(privKey) {
    return utils.bytesToHex(secp.schnorr.getPublicKey(privKey));
}
export async function openFile() {
    return new Promise(resolve => {
        const elm = document.createElement("input");
        elm.type = "file";
        elm.onchange = (e) => {
            const elm = e.target;
            if (elm.files) {
                resolve(elm.files[0]);
            }
            else {
                resolve(undefined);
            }
        };
        elm.click();
    });
}
/**
 * Parse bech32 ids
 * https://github.com/nostr-protocol/nips/blob/master/19.md
 * @param id bech32 id
 */
export function parseId(id) {
    const hrp = ["note", "npub", "nsec"];
    try {
        if (hrp.some(a => id.startsWith(a))) {
            return bech32ToHex(id);
        }
    }
    catch (e) {
        // Ignore the error.
    }
    return id;
}
export function bech32ToHex(str) {
    try {
        const nKey = bech32.decode(str, 1000);
        const buff = bech32.fromWords(nKey.words);
        return utils.bytesToHex(Uint8Array.from(buff));
    }
    catch (e) {
        return str;
    }
}
/**
 * Decode bech32 to string UTF-8
 * @param str bech32 encoded string
 * @returns
 */
export function bech32ToText(str) {
    try {
        const decoded = bech32.decode(str, 1000);
        const buf = bech32.fromWords(decoded.words);
        return new TextDecoder().decode(Uint8Array.from(buf));
    }
    catch {
        return "";
    }
}
/**
 * Convert hex note id to bech32 link url
 * @param hex
 * @returns
 */
export function eventLink(hex, relays) {
    const encoded = relays
        ? encodeTLV(NostrPrefix.Event, hex, Array.isArray(relays) ? relays : [relays])
        : hexToBech32(NostrPrefix.Note, hex);
    return `/e/${encoded}`;
}
/**
 * Convert hex pubkey to bech32 link url
 */
export function profileLink(hex, relays) {
    const encoded = relays
        ? encodeTLV(NostrPrefix.Event, hex, Array.isArray(relays) ? relays : [relays])
        : hexToBech32(NostrPrefix.PublicKey, hex);
    return `/p/${encoded}`;
}
/**
 * Convert hex to bech32
 */
export function hexToBech32(hrp, hex) {
    if (typeof hex !== "string" || hex.length === 0 || hex.length % 2 !== 0) {
        return "";
    }
    try {
        if (hrp === NostrPrefix.Note || hrp === NostrPrefix.PrivateKey || hrp === NostrPrefix.PublicKey) {
            const buf = utils.hexToBytes(hex);
            return bech32.encode(hrp, bech32.toWords(buf));
        }
        else {
            return encodeTLV(hrp, hex);
        }
    }
    catch (e) {
        console.warn("Invalid hex", hex, e);
        return "";
    }
}
/**
 * Reaction types
 */
export const Reaction = {
    Positive: "+",
    Negative: "-",
};
/**
 * Return normalized reaction content
 */
export function normalizeReaction(content) {
    switch (content) {
        case "-":
            return Reaction.Negative;
        case "👎":
            return Reaction.Negative;
        default:
            return Reaction.Positive;
    }
}
/**
 * Get reactions to a specific event (#e + kind filter)
 */
export function getReactions(notes, id, kind) {
    return notes?.filter(a => a.kind === (kind ?? a.kind) && a.tags.some(a => a[0] === "e" && a[1] === id)) || [];
}
export function getAllReactions(notes, ids, kind) {
    return notes?.filter(a => a.kind === (kind ?? a.kind) && a.tags.some(a => a[0] === "e" && ids.includes(a[1]))) || [];
}
export function unixNow() {
    return Math.floor(unixNowMs() / 1000);
}
export function unixNowMs() {
    return new Date().getTime();
}
export function deepClone(obj) {
    if ("structuredClone" in window) {
        return structuredClone(obj);
    }
    else {
        return JSON.parse(JSON.stringify(obj));
    }
}
/**
 * Simple debounce
 */
export function debounce(timeout, fn) {
    const t = setTimeout(fn, timeout);
    return () => clearTimeout(t);
}
export function dedupeByPubkey(events) {
    const deduped = events.reduce(({ list, seen }, ev) => {
        if (seen.has(ev.pubkey)) {
            return { list, seen };
        }
        seen.add(ev.pubkey);
        return {
            seen,
            list: [...list, ev],
        };
    }, { list: [], seen: new Set([]) });
    return deduped.list;
}
export function dedupeById(events) {
    const deduped = events.reduce(({ list, seen }, ev) => {
        if (seen.has(ev.id)) {
            return { list, seen };
        }
        seen.add(ev.id);
        return {
            seen,
            list: [...list, ev],
        };
    }, { list: [], seen: new Set([]) });
    return deduped.list;
}
/**
 * Return newest event by pubkey
 * @param events List of all notes to filter from
 * @returns
 */
export function getLatestByPubkey(events) {
    const deduped = events.reduce((results, ev) => {
        if (!results.has(ev.pubkey)) {
            const latest = getNewest(events.filter(a => a.pubkey === ev.pubkey));
            if (latest) {
                results.set(ev.pubkey, latest);
            }
        }
        return results;
    }, new Map());
    return deduped;
}
export function getLatestProfileByPubkey(profiles) {
    const deduped = profiles.reduce((results, ev) => {
        if (!results.has(ev.pubkey)) {
            const latest = getNewestProfile(profiles.filter(a => a.pubkey === ev.pubkey));
            if (latest) {
                results.set(ev.pubkey, latest);
            }
        }
        return results;
    }, new Map());
    return deduped;
}
export function dedupe(v) {
    return [...new Set(v)];
}
export function appendDedupe(a, b) {
    return dedupe([...(a ?? []), ...(b ?? [])]);
}
export function unwrap(v) {
    if (v === undefined || v === null) {
        throw new Error("missing value");
    }
    return v;
}
export function randomSample(coll, size) {
    const random = [...coll];
    return random.sort(() => (Math.random() >= 0.5 ? 1 : -1)).slice(0, size);
}
export function getNewest(rawNotes) {
    const notes = [...rawNotes];
    notes.sort((a, b) => b.created_at - a.created_at);
    if (notes.length > 0) {
        return notes[0];
    }
}
export function getNewestProfile(rawNotes) {
    const notes = [...rawNotes];
    notes.sort((a, b) => b.created - a.created);
    if (notes.length > 0) {
        return notes[0];
    }
}
export function getNewestEventTagsByKey(evs, tag) {
    const newest = getNewest(evs);
    if (newest) {
        const keys = newest.tags.filter(p => p && p.length === 2 && p[0] === tag).map(p => p[1]);
        return {
            keys,
            createdAt: newest.created_at,
        };
    }
}
export function tagFilterOfTextRepost(note, id) {
    return (tag, i) => tag[0] === "e" && tag[3] === "mention" && note.content === `#[${i}]` && (id ? tag[1] === id : true);
}
export function groupByPubkey(acc, user) {
    return { ...acc, [user.pubkey]: user };
}
export function splitByUrl(str) {
    const urlRegex = /((?:http|ftp|https|nostr|web\+nostr|magnet):\/?\/?(?:[\w+?.\w+])+(?:[a-zA-Z0-9~!@#$%^&*()_\-=+\\/?.:;',]*)?(?:[-A-Za-z0-9+&@#/%=~()_|]))/i;
    return str.split(urlRegex);
}
export const delay = (t) => {
    return new Promise(resolve => {
        setTimeout(resolve, t);
    });
};
export function decodeInvoice(pr) {
    try {
        const parsed = invoiceDecode(pr);
        const amountSection = parsed.sections.find(a => a.name === "amount");
        const amount = amountSection ? Number(amountSection.value) : undefined;
        const timestampSection = parsed.sections.find(a => a.name === "timestamp");
        const timestamp = timestampSection ? Number(timestampSection.value) : undefined;
        const expirySection = parsed.sections.find(a => a.name === "expiry");
        const expire = expirySection ? Number(expirySection.value) : undefined;
        const descriptionSection = parsed.sections.find(a => a.name === "description")?.value;
        const descriptionHashSection = parsed.sections.find(a => a.name === "description_hash")?.value;
        const paymentHashSection = parsed.sections.find(a => a.name === "payment_hash")?.value;
        const ret = {
            pr,
            amount: amount,
            expire: timestamp && expire ? timestamp + expire : undefined,
            timestamp: timestamp,
            description: descriptionSection,
            descriptionHash: descriptionHashSection ? bytesToHex(descriptionHashSection) : undefined,
            paymentHash: paymentHashSection ? bytesToHex(paymentHashSection) : undefined,
            expired: false,
        };
        if (ret.expire) {
            ret.expired = ret.expire < new Date().getTime() / 1000;
        }
        return ret;
    }
    catch (e) {
        console.error(e);
    }
}
/**
 * Parse a magnet URI and return an object of keys/values
 */
export function magnetURIDecode(uri) {
    try {
        const result = {
            raw: uri,
        };
        // Support 'magnet:' and 'stream-magnet:' uris
        const data = uri.trim().split("magnet:?")[1];
        const params = data && data.length > 0 ? data.split("&") : [];
        params.forEach(param => {
            const split = param.split("=");
            const key = split[0];
            const val = decodeURIComponent(split[1]);
            if (!result[key]) {
                result[key] = [];
            }
            switch (key) {
                case "dn": {
                    result[key].push(val.replace(/\+/g, " "));
                    break;
                }
                case "kt": {
                    val.split("+").forEach(e => {
                        result[key].push(e);
                    });
                    break;
                }
                case "ix": {
                    result[key].push(Number(val));
                    break;
                }
                case "so": {
                    // todo: not implemented yet
                    break;
                }
                default: {
                    result[key].push(val);
                    break;
                }
            }
        });
        // Convenience properties for parity with `parse-torrent-file` module
        let m;
        if (result.xt) {
            const xts = Array.isArray(result.xt) ? result.xt : [result.xt];
            xts.forEach(xt => {
                if (typeof xt === "string") {
                    if ((m = xt.match(/^urn:btih:(.{40})/))) {
                        result.infoHash = [m[1].toLowerCase()];
                    }
                    else if ((m = xt.match(/^urn:btih:(.{32})/))) {
                        const decodedStr = base32hex.decode(m[1]);
                        result.infoHash = [bytesToHex(decodedStr)];
                    }
                    else if ((m = xt.match(/^urn:btmh:1220(.{64})/))) {
                        result.infoHashV2 = [m[1].toLowerCase()];
                    }
                }
            });
        }
        if (result.xs) {
            const xss = Array.isArray(result.xs) ? result.xs : [result.xs];
            xss.forEach(xs => {
                if (typeof xs === "string" && (m = xs.match(/^urn:btpk:(.{64})/))) {
                    if (!result.publicKey) {
                        result.publicKey = [];
                    }
                    result.publicKey.push(m[1].toLowerCase());
                }
            });
        }
        for (const [k, v] of Object.entries(result)) {
            if (Array.isArray(v)) {
                if (v.length === 1) {
                    result[k] = v[0];
                }
                else if (v.length === 0) {
                    result[k] = undefined;
                }
            }
        }
        return result;
    }
    catch (e) {
        console.warn("Failed to parse magnet link", e);
    }
}
export function chunks(arr, length) {
    const result = [];
    let idx = 0;
    let n = arr.length / length;
    while (n > 0) {
        result.push(arr.slice(idx, idx + length));
        idx += length;
        n -= 1;
    }
    return result;
}
export function findTag(e, tag) {
    const maybeTag = e.tags.find(evTag => {
        return evTag[0] === tag;
    });
    return maybeTag && maybeTag[1];
}
export function hmacSha256(key, ...messages) {
    return hmac(hash, key, utils.concatBytes(...messages));
}
export function getRelayName(url) {
    const parsedUrl = new URL(url);
    return parsedUrl.host + parsedUrl.search;
}
export function getUrlHostname(url) {
    try {
        return new URL(url ?? "").hostname;
    }
    catch {
        return url?.match(/(\S+\.\S+)/i)?.[1] ?? url;
    }
}
export function sanitizeRelayUrl(url) {
    try {
        return new URL(url).toString();
    }
    catch {
        // ignore
    }
}
export function kvToObject(o, sep) {
    return Object.fromEntries(o.split(sep ?? ",").map(v => {
        const match = v.trim().match(/^(\w+)="(.*)"$/);
        if (match) {
            return [match[1], match[2]];
        }
        return [];
    }));
}
