import { useSyncExternalStore } from "react";
import { InteractionCache } from "Cache/EventInteractionCache";
import { sha256, unwrap } from "SnortUtils";
export function useInteractionCache(pubkey, event) {
    const id = event && pubkey ? sha256(event + pubkey) : undefined;
    const EmptyInteraction = {
        id,
        event,
        by: pubkey,
    };
    const data = useSyncExternalStore(c => InteractionCache.hook(c, id), () => InteractionCache.snapshot().find(a => a.id === id)) || EmptyInteraction;
    return {
        data: data,
        react: () => InteractionCache.set({
            ...data,
            event: unwrap(event),
            by: unwrap(pubkey),
            reacted: true,
        }),
        zap: () => InteractionCache.set({
            ...data,
            event: unwrap(event),
            by: unwrap(pubkey),
            zapped: true,
        }),
        repost: () => InteractionCache.set({
            ...data,
            event: unwrap(event),
            by: unwrap(pubkey),
            reposted: true,
        }),
    };
}
